import React from "react"

import { BannerButtonStyled } from "./BannerButton.styled"
import { BannerButtonProps } from "./BannerButton.types"

const BannerButton = React.memo((props: BannerButtonProps) => {
  const {
    href,
    onClick,
    label,
    eventPlace,
    variant = "contained",
    color = "primary",
    metadata,
  } = props
  return (
    <BannerButtonStyled
      variant={variant}
      color={color}
      data-place={eventPlace}
      data-title={metadata.title}
      data-subtitle={metadata.subtitle}
      href={href}
      onClick={onClick}
    >
      {label}
    </BannerButtonStyled>
  )
})

export { BannerButton }
