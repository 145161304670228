import React from "react"

import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded"
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded"
import GitHubIcon from "@mui/icons-material/GitHub"
import HardwareRoundedIcon from "@mui/icons-material/HardwareRounded"
import HelpRoundedIcon from "@mui/icons-material/HelpRounded"
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded"
import InstagramIcon from "@mui/icons-material/Instagram"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded"
import MailRoundedIcon from "@mui/icons-material/MailRounded"
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded"
import SupportRoundedIcon from "@mui/icons-material/SupportRounded"
import XIcon from "@mui/icons-material/X"
import YouTubeIcon from "@mui/icons-material/YouTube"
import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import env from "decentraland-gatsby/dist/utils/env"
import { useMobileMediaQuery } from "decentraland-ui2/dist/components/Media"

import { SectionViewedTrack } from "../../../modules/segment"
import { DiscordIcon } from "../../Icon/DiscordIcon"
import { TiktokIcon } from "../../Icon/TiktokIcon"
import {
  FooterContainer,
  IconButton,
  LinksContainer,
  LinksTitle,
  LinksWrapper,
  SocialContainer,
  SocialIconBox,
  SocialIconWrapper,
  SubscribeContainer,
  SubscribeSubtitle,
  SubscribeTitle,
  SubscriptionBeehiiv,
} from "./LandingFooterV2.styled"

const LandingFooterV2 = React.memo(() => {
  const l = useFormatMessage()

  const handleMainCTA = useTrackLinkContext()

  const isMobile = useMobileMediaQuery()

  return (
    <FooterContainer>
      <SubscribeContainer>
        <SubscribeSubtitle variant={isMobile ? "h3" : "h4"}>
          {l("component.landing.footer.subscribe.stay_updated_with")}
        </SubscribeSubtitle>
        <SubscribeTitle variant={isMobile ? "h1" : "h2"}>
          {l("component.landing.footer.subscribe.decentraland")}
        </SubscribeTitle>
        <SubscriptionBeehiiv
          src={l("component.landing.footer.subscribe.src")}
          data-test-id="beehiiv-embed"
        ></SubscriptionBeehiiv>
        <SocialContainer>
          <SocialIconWrapper>
            <SocialIconBox>
              <DiscordIcon
                href={env("DISCORD_URL")}
                fontSize="inherit"
                onClick={handleMainCTA}
                data-place={SectionViewedTrack.LandingFooter}
              />
            </SocialIconBox>
            <SocialIconBox>
              <GitHubIcon
                href={env("GITHUB_URL")}
                fontSize="inherit"
                onClick={handleMainCTA}
                data-place={SectionViewedTrack.LandingFooter}
              />
            </SocialIconBox>
            <SocialIconBox>
              <XIcon
                href={env("X_URL")}
                fontSize="inherit"
                onClick={handleMainCTA}
                data-place={SectionViewedTrack.LandingFooter}
              />
            </SocialIconBox>
            <SocialIconBox>
              <InstagramIcon
                href={env("INSTAGRAM_URL")}
                fontSize="inherit"
                onClick={handleMainCTA}
                data-place={SectionViewedTrack.LandingFooter}
              />
            </SocialIconBox>
            <SocialIconBox>
              <YouTubeIcon
                href={env("YOUTUBE_URL")}
                fontSize="inherit"
                onClick={handleMainCTA}
                data-place={SectionViewedTrack.LandingFooter}
              />
            </SocialIconBox>
            <SocialIconBox>
              <TiktokIcon
                href={env("TIKTOK_URL")}
                fontSize="inherit"
                onClick={handleMainCTA}
                data-place={SectionViewedTrack.LandingFooter}
              />
            </SocialIconBox>
            <SocialIconBox>
              <LinkedInIcon
                href={env("LINKEDIN_URL")}
                fontSize="inherit"
                onClick={handleMainCTA}
                data-place={SectionViewedTrack.LandingFooter}
              />
            </SocialIconBox>
          </SocialIconWrapper>
        </SocialContainer>
      </SubscribeContainer>
      <LinksContainer>
        <LinksWrapper>
          <LinksTitle variant="body1">
            {l("component.landing.footer.social.resources")}
          </LinksTitle>
          <IconButton
            variant="text"
            color="secondary"
            startIcon={
              <LocalOfferRoundedIcon sx={{ transform: "scaleX(-1)" }} />
            }
            href={env("MARKETPLACE_URL")}
            onClick={handleMainCTA}
            data-place={SectionViewedTrack.LandingFooter}
          >
            {l("component.landing.footer.social.marketplace")}
          </IconButton>
          <IconButton
            variant="text"
            color="secondary"
            startIcon={<HardwareRoundedIcon />}
            href={env("CREATE_URL")}
            onClick={handleMainCTA}
            data-place={SectionViewedTrack.LandingFooter}
          >
            {l("component.landing.footer.social.creator_hub")}
          </IconButton>
          <IconButton
            variant="text"
            color="secondary"
            startIcon={<InsertDriveFileRoundedIcon />}
            href={env("DOCS_URL")}
            onClick={handleMainCTA}
            data-place={SectionViewedTrack.LandingFooter}
          >
            {l("component.landing.footer.social.docs")}
          </IconButton>
          <IconButton
            variant="text"
            color="secondary"
            startIcon={<CalendarMonthRoundedIcon />}
            href={env("EVENTS_URL")}
            onClick={handleMainCTA}
            data-place={SectionViewedTrack.LandingFooter}
          >
            {l("component.landing.footer.social.events")}
          </IconButton>
          <IconButton
            variant="text"
            color="secondary"
            startIcon={<ArticleRoundedIcon />}
            href={env("BLOG_URL")}
            onClick={handleMainCTA}
            data-place={SectionViewedTrack.LandingFooter}
          >
            {l("component.landing.footer.social.blog")}
          </IconButton>
        </LinksWrapper>
        <LinksWrapper>
          <LinksTitle variant="body1">
            {l("component.landing.footer.social.connect")}
          </LinksTitle>
          <IconButton
            variant="text"
            color="secondary"
            startIcon={<SupportRoundedIcon />}
            href={env("SUPPORT_URL")}
            onClick={handleMainCTA}
            data-place={SectionViewedTrack.LandingFooter}
          >
            {l("component.landing.footer.social.support")}
          </IconButton>
          <IconButton
            variant="text"
            color="secondary"
            startIcon={<MailRoundedIcon />}
            href={env("EMAIL_HELLO_URL")}
            onClick={handleMainCTA}
            data-place={SectionViewedTrack.LandingFooter}
          >
            {l("component.landing.footer.social.email")}
          </IconButton>
          <IconButton
            variant="text"
            color="secondary"
            startIcon={<PeopleAltRoundedIcon />}
            href={env("DAO_URL")}
            onClick={handleMainCTA}
            data-place={SectionViewedTrack.LandingFooter}
          >
            {l("component.landing.footer.social.dao")}
          </IconButton>
          <IconButton
            variant="text"
            color="secondary"
            startIcon={<HelpRoundedIcon />}
            href={env("FAQ_URL")}
            onClick={handleMainCTA}
            data-place={SectionViewedTrack.LandingFooter}
          >
            {l("component.landing.footer.social.faq")}
          </IconButton>
        </LinksWrapper>
      </LinksContainer>
    </FooterContainer>
  )
})

export { LandingFooterV2 }
