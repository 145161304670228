import React, { useRef } from "react"

import { useInView } from "react-intersection-observer"

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded"
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded"
import { useMobileMediaQuery } from "decentraland-ui2/dist/components/Media"
import {
  Autoplay,
  EffectCoverflow,
  Lazy,
  Navigation,
  Swiper as SwiperClass,
} from "swiper"

import { ContentfulWhatsHotListProps } from "../../../hooks/useLandingContentfulQuery"
import {
  NavigationButton,
  SwiperSlideStyled,
  SwiperStyled,
  WhatsHotContainer,
  WhatsHotSection,
  WhatsHotTitle,
} from "./LandingWhatsHot.styled"
import { LandingWhatsHotSlide } from "./LandingWhatsHotSlide"

const LandingWhatsHot = React.memo((props: ContentfulWhatsHotListProps) => {
  const { list } = props

  const [sectionInView, isSectionInView] = useInView({ threshold: 0.1 })

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  const swiperRef = useRef<SwiperClass>()

  const isMobile = useMobileMediaQuery()

  return (
    <WhatsHotSection>
      <WhatsHotTitle variant="h3">What’s Hot?</WhatsHotTitle>
      <WhatsHotContainer ref={sectionInView}>
        <SwiperStyled
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper
          }}
          modules={[Autoplay, EffectCoverflow, Lazy, Navigation]}
          loop={true}
          effect="coverflow"
          coverflowEffect={{
            rotate: 25,
            scale: 0.8,
            depth: 250,
          }}
          autoplay={{
            delay: 20000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          lazy={{
            enabled: false,
            loadPrevNext: true,
            loadPrevNextAmount: 20,
          }}
          navigation={
            !isMobile && {
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }
          }
        >
          {list.map((slide, index) => (
            <SwiperSlideStyled key={index}>
              <LandingWhatsHotSlide key={index} {...slide} />
            </SwiperSlideStyled>
          ))}
        </SwiperStyled>

        {!isMobile && (
          <>
            <NavigationButton direction="prev" ref={navigationPrevRef}>
              <ArrowBackIosNewRoundedIcon />
            </NavigationButton>
            <NavigationButton direction="next" ref={navigationNextRef}>
              <ArrowForwardIosRoundedIcon />
            </NavigationButton>
          </>
        )}
      </WhatsHotContainer>
    </WhatsHotSection>
  )
})

export { LandingWhatsHot }
