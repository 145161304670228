import React from "react"

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const DiscordIcon = React.memo((props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6903 5.44387C12.3244 5.44551 9.29748 5.54141 6.10424 8.18988C6.10424 8.18988 2.69812 15.0281 2.69812 23.4504C2.69812 23.4504 4.68499 27.2523 9.91245 27.4371C9.91245 27.4371 10.7876 26.2754 11.4972 25.2721C8.49322 24.2688 7.35786 22.1831 7.35786 22.1831C7.35786 22.1831 7.59444 22.3679 8.02021 22.6319C8.03482 22.6319 8.04941 22.642 8.06959 22.6559C8.08207 22.6645 8.09668 22.6746 8.11475 22.6847C8.15023 22.7111 8.18572 22.7309 8.2212 22.7507C8.25669 22.7705 8.29218 22.7903 8.32766 22.8167C8.919 23.1863 9.51032 23.4768 10.0544 23.7144C11.0242 24.1633 12.1832 24.5593 13.5315 24.8497C15.3055 25.2193 17.387 25.3513 19.6577 24.8761C20.7694 24.6384 21.9049 24.2953 23.0876 23.7408C23.9155 23.3976 24.8379 22.896 25.8077 22.1831C25.8077 22.1831 24.625 24.3217 21.5264 25.2986C22.236 26.2755 23.0876 27.4107 23.0876 27.4107C28.0401 27.2356 30.0839 23.8139 30.2852 23.4769C30.2964 23.4582 30.3019 23.449 30.3019 23.4504C30.3019 15.0281 26.8958 8.18988 26.8958 8.18988C23.5133 5.36484 20.2727 5.44407 20.2727 5.44407L19.9416 5.8665C23.9628 7.21301 25.8313 9.19322 25.8313 9.19322C23.3714 7.7147 20.9587 6.97538 18.7117 6.68496C17.0086 6.47374 15.3764 6.52661 13.9336 6.73783C13.8096 6.73783 13.7036 6.75795 13.5842 6.78064L13.5315 6.79059C12.7036 6.8962 10.6931 7.213 8.16211 8.4539C7.28693 8.87634 6.7665 9.19322 6.7665 9.19322C6.7665 9.19322 8.7061 7.10738 12.9638 5.76086L12.7272 5.44407C12.7272 5.44407 12.7147 5.44377 12.6903 5.44387ZM12.0886 15.1863C13.4369 15.1863 14.525 16.48 14.5013 18.0905C14.5013 19.701 13.4369 20.9948 12.0886 20.9948C10.764 20.9948 9.67595 19.701 9.67595 18.0905C9.67595 16.48 10.7404 15.1863 12.0886 15.1863ZM23.1353 18.0905C23.1353 16.48 22.0472 15.1863 20.7226 15.1863C19.3744 15.1863 18.31 16.48 18.31 18.0905C18.31 19.701 19.398 20.9948 20.7226 20.9948C22.0709 20.9948 23.1353 19.701 23.1353 18.0905Z"
          fill="#FCFCFC"
        />
      </svg>
    </SvgIcon>
  )
})

export { DiscordIcon }
