import { neutral } from "decentraland-ui2/dist/theme/colors"

import { Box, Button, Typography, styled, useTheme } from "decentraland-ui2"

const WhatsHotSlideContainer = styled(Box)({
  backgroundColor: neutral.softBlack2,
  paddingBottom: "24px",
})

const WhatsHotSlide = styled(Box)(() => {
  const theme = useTheme()
  return {
    position: "relative",
    height: "260px",
    width: "520px",
    borderTopRightRadius: "16px",
    borderTopLeftRadius: "16px",
    overflow: "hidden",
    "::after": {
      content: "''",
      position: "absolute",
      top: "50%",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
    },
    [theme.breakpoints.down("xs")]: {
      width: "80vh",
      height: 0,
      paddingTop: "50%",
    },
  }
})

const WhatsHotSlideContent = styled(Box)({})

const WhatsHotSlideTextContainer = styled(Box)(() => {
  const theme = useTheme()
  return {
    zIndex: 2,
    margin: "24px 32px 0 32px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      marginRight: "20px",
    },
  }
})

const WhatsHotSlideTitle = styled(Typography)(() => {
  const theme = useTheme()
  return {
    marginBottom: "24px",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  }
})

const WhatsHotSlideSubtitle = styled(Typography)(() => {
  const theme = useTheme()

  return {
    color: theme.palette.text.secondary,
  }
})

const WhatsHotSlideActionsContainer = styled(Box)({
  marginTop: "34px",
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
})

const WhatsHotSlideButton = styled(Button)(() => {
  const theme = useTheme()
  return {
    "&.MuiButton-sizeMedium.MuiButton-outlinedSecondary:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)":
      { color: theme.palette.secondary.main },
    "&.MuiButton-sizeMedium.MuiButton-outlinedSecondary:not(.Mui-disabled):not(.Mui-focusVisible):hover":
      { color: theme.palette.secondary.main },
    "&.MuiButton-sizeMedium.MuiButton-outlinedSecondary": {
      height: "54px",
    },
    [theme.breakpoints.down("xs")]: {
      "&.MuiButton-sizeMedium.MuiButton-outlinedSecondary": {
        height: "54px",
      },
    },
  }
})

const WhatsHotSlideImageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "imageUrl",
})((props: { imageUrl?: string }) => {
  const { imageUrl } = props
  return {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }
})

export {
  WhatsHotSlideContainer,
  WhatsHotSlide,
  WhatsHotSlideContent,
  WhatsHotSlideTextContainer,
  WhatsHotSlideTitle,
  WhatsHotSlideSubtitle,
  WhatsHotSlideActionsContainer,
  WhatsHotSlideButton,
  WhatsHotSlideImageContainer,
}
