import React from "react"

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const TiktokIcon = React.memo((props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.82 2.44385C8.9107 2.44385 2.5 8.85455 2.5 16.7638C2.5 24.6732 8.9107 31.0838 16.82 31.0838C24.7293 31.0838 31.14 24.6732 31.14 16.7638C31.14 8.85455 24.7293 2.44385 16.82 2.44385ZM24.3696 14.799C22.8977 14.8656 21.5956 14.3794 20.3201 13.5635C20.3001 13.7666 20.2768 13.9165 20.2768 14.0664C20.2735 15.8813 20.2834 17.693 20.2735 19.508C20.2568 22.5684 18.0655 24.803 14.8885 25.0095C12.3475 25.176 9.8032 22.9647 9.40357 20.2439C8.92735 16.9936 11.6715 13.9565 14.9484 14.1163C15.1249 14.1263 15.3014 14.1596 15.5079 14.1862C15.5079 15.1487 15.5079 16.0812 15.5079 17.0769C15.3514 17.0769 15.1982 17.0835 15.045 17.0769C13.563 16.997 12.3808 18.0227 12.3275 19.438C12.2776 20.7868 13.3366 21.9524 14.6587 22.0023C16.154 22.0589 17.3062 21.0099 17.3129 19.518C17.3329 15.8913 17.3195 12.2647 17.3195 8.63475C17.3195 8.37499 17.3195 8.11523 17.3195 7.82217C18.3552 7.82217 19.2877 7.82217 20.2235 7.82217C20.5066 10.3032 21.9386 11.612 24.363 11.9517C24.3696 12.8875 24.3696 13.8099 24.3696 14.799Z"
          fill="#FCFCFC"
        />
      </svg>
    </SvgIcon>
  )
})

export { TiktokIcon }
